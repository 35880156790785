import { useState, useEffect } from "react";

import { Text, Modal, Group, ModalCloseButton, Divider } from "@mantine/core";
import s from "./modal.module.css";

function isIOS() {
  if (/iPad|iPhone|iPod/.test(navigator.platform)) {
    return true;
  } else {
    return (
      navigator.maxTouchPoints &&
      navigator.maxTouchPoints > 2 &&
      /MacIntel/.test(navigator.platform)
    );
  }
}

export function isIpadOS() {
  return (
    navigator.maxTouchPoints &&
    navigator.maxTouchPoints > 2 &&
    /MacIntel/.test(navigator.platform)
  );
}

interface InstalliOSModalProps {
  installiOSModalStatus: boolean;
  isInstalliOSModalShown: boolean;
  openInstalliOSModal: () => void;
  closeInstalliOSModalHandler: () => void;
}

export default function InstalliOSModal(props: InstalliOSModalProps) {
  const {
    installiOSModalStatus,
    isInstalliOSModalShown,
    openInstalliOSModal,
    closeInstalliOSModalHandler,
  } = props;

  const [isStandalone, setIsStandalone] = useState<boolean>(false); // is installed (works on iOS)

  useEffect(() => {
    setIsStandalone(
      Boolean("standalone" in window.navigator && window.navigator.standalone)
    );
  }, []);

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;
    if (!isInstalliOSModalShown && !isStandalone && (isIOS() || isIpadOS())) {
      timeout = setTimeout(openInstalliOSModal, 2000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [isInstalliOSModalShown, isStandalone]);

  return (
    <Modal
      opened={installiOSModalStatus}
      onClose={() => {}}
      size="sm"
      transitionProps={{ transition: "slide-down" }}
      radius="md"
      withOverlay={false}
      withCloseButton={false}
      yOffset="1rem"
    >
      <Group align="flex-start" wrap="nowrap" gap="xs">
        <img className={s.modalInstalliOSIcon} src="./icons/maskable-192.png" />
        <Text size="sm">
          You can install a Pomomini app on your device and use it as standalone
          app.
        </Text>
        <ModalCloseButton
          onClick={closeInstalliOSModalHandler}
        ></ModalCloseButton>
      </Group>
      <Divider my="sm" />
      <Group mt="sm" wrap="nowrap" gap="xs" align="flex-end">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          x="0px"
          y="0px"
          viewBox="0 0 256 256"
          enable-background="new 0 0 256 256"
          className={s.iconShare}
        >
          <path d="M195.4,77.4h-33.7v8.4h33.7c4.6,0,8.4,3.8,8.4,8.4v134.9c0,4.6-3.8,8.4-8.4,8.4H60.6c-4.6,0-8.4-3.8-8.4-8.4V94.3c0-4.6,3.8-8.4,8.4-8.4h33.7v-8.4H60.6c-9.3,0-16.9,7.5-16.9,16.9v134.9c0,9.3,7.5,16.9,16.9,16.9h134.9c9.3,0,16.9-7.5,16.9-16.9V94.3C212.3,85,204.7,77.4,195.4,77.4z M97.3,50.9l26.5-26.5v133.1c0,2.3,1.9,4.2,4.2,4.2c2.3,0,4.2-1.9,4.2-4.2V24.4l26.5,26.5c0.8,0.8,1.9,1.2,3,1.2s2.2-0.4,3-1.2c1.6-1.6,1.6-4.3,0-5.9L131,11.2c-1.6-1.6-4.3-1.6-5.9,0L91.3,44.9c-1.6,1.6-1.6,4.3,0,5.9C93,52.6,95.6,52.6,97.3,50.9z" />
        </svg>{" "}
        <Text size="sm">Tap the “share” icon</Text>
      </Group>
      <Group mt="sm" wrap="nowrap" gap="xs" align="flex-end">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          x="0px"
          y="0px"
          viewBox="0 0 256 256"
          enable-background="new 0 0 256 256"
          className={s.iconAdd}
        >
          <path d="M41,11.2c-13.9,4.4-24.6,14.2-28.5,26c-2.6,7.6-2.8,20.7-2.4,94.6l0.6,85.9l5.5,8.9c4.1,6.6,8.3,10.5,15.5,14.2l9.8,5.2H128h86.5l9.8-5.2c7.2-3.7,11.5-7.6,15.5-14.2l5.5-8.9l0.6-83.3c0.4-55.8,0-86.5-1.5-92.7C242,30.6,234,20.2,223.5,14.5c-7.6-4.1-8.9-4.1-92.7-4.4C84,9.9,43.6,10.4,41,11.2z M221.3,25.2c14.2,8.1,13.9,5.9,13.9,104v86.6l-4.2,6.1c-2.2,3.5-7,7.9-10.5,10c-6.3,3.7-9,3.9-92.4,3.9c-83.3,0-86.1-0.2-92.4-3.9c-3.5-2-8.3-6.5-10.5-10l-4.2-6.1v-87.9V40l4.4-6.7c8.9-12.9,7-12.7,102.5-12.7h85.7L221.3,25.2z" />
          <path d="M122.8,74.5c-1.5,1.5-2.2,9.6-2.2,24v21.8h-20.1c-15.9,0-20.9,0.6-23.1,3c-1.7,1.5-3,3.9-3,5.2c0,5.4,4.6,6.7,25.3,6.7h20.9v23.1c0,21.1,0.4,23.1,3.7,24.9c2.6,1.5,4.8,1.5,7.4,0c3.3-1.8,3.7-3.9,3.7-24.9v-23.1h20.9c20.7,0,25.3-1.3,25.3-6.7c0-1.3-1.3-3.7-3-5.2c-2.2-2.4-7.2-3-23.1-3h-20.1V98.6c0-22.2-1.1-26.2-7.4-26.2C126.3,72.3,124.1,73.2,122.8,74.5z" />
        </svg>
        <Text size="sm">Choose “Add to Home Screen”</Text>
      </Group>
    </Modal>
  );
}
