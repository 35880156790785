import {
  ActionIcon,
  Box,
  Group,
  NativeSelect,
  SegmentedControl,
  Switch,
  Title,
  Text,
  useMantineTheme,
  Slider,
  Stack,
  parseThemeColor,
  useComputedColorScheme,
  useMantineColorScheme,
  Modal,
} from "@mantine/core";
import s from "./settings.module.css";
import { shortName } from "configs/meta";
import isAvailable from "utils/isAvailable";
import {
  IconArrowLeft,
  IconChevronRight,
  IconMoon,
  IconSettingsCheck,
  IconSun,
} from "@tabler/icons-react";
import Label from "components/settings/Label";
import { useDisclosure, useLocalStorage, useOs } from "@mantine/hooks";
import {
  UI_ANIMATION_SPEED,
  CONFIG_FOCUS_TIME_MAP_TO_MILISECONDS,
  CONFIG_FOCUS_TIME_OPTION_ARRAY,
  CONFIG_FOCUS_TIME_OPTION_DEFAULT,
  CONFIG_IS_KEEP_WAKE_DEFAULT,
  CONFIG_IS_PLAY_SOUND_OPTION_DEFAULT,
  CONFIG_IS_VIBRATION_OPTION_DEFAULT,
  CONFIG_REST_TIME_MAP_TO_MILISECONDS,
  CONFIG_REST_TIME_OPTION_ARRAY,
  CONFIG_REST_TIME_OPTION_DEFAULT,
} from "configs/appSettings";
import { useEffect, useRef, useState } from "react";
import permission from "utils/checkPermission";
import {
  STORAGE_KEY_FOCUS_TIME,
  STORAGE_KEY_KEEP_WAKE,
  STORAGE_KEY_NOTIFICATIONS,
  STORAGE_KEY_PLAY_SOUND,
  STORAGE_KEY_REST_TIME,
  STORAGE_KEY_VIBRATION,
} from "constants/storageKeys";
import useThemeColor from "utils/useThemeColor";
import { isIpadOS } from "components/modals/InstalliOS";
import { Link, useNavigate } from "react-router-dom";
import { cubicBezier, motion } from "framer-motion";
import { useColorTransition } from "utils/useColorTransition";

export default function Settings() {
  const navigate = useNavigate();
  const os = useOs();
  const myRef = useRef<HTMLElement | null>(null);

  const theme = useMantineTheme();
  const { setThemeColor, matchThemeColor } = useThemeColor();
  const { smoothColorTransition } = useColorTransition();

  const [opened, { close, open }] = useDisclosure(false);

  const [scrolled, setScrolled] = useState<boolean>(false);

  // start:settings
  const [focusTimeKey, setFocusTimeKey] =
    useLocalStorage<CONFIG_FOCUS_TIME_OPTION_ARRAY>({
      key: STORAGE_KEY_FOCUS_TIME,
      defaultValue: CONFIG_FOCUS_TIME_OPTION_DEFAULT,
      getInitialValueInEffect: false,
    });
  const [restTimeKey, setRestTimeKey] =
    useLocalStorage<CONFIG_REST_TIME_OPTION_ARRAY>({
      key: STORAGE_KEY_REST_TIME,
      defaultValue: CONFIG_REST_TIME_OPTION_DEFAULT,
      getInitialValueInEffect: false,
    });
  const [isNotificationsEnabled, setNotifications] = useLocalStorage<boolean>({
    key: STORAGE_KEY_NOTIFICATIONS,
    defaultValue: false, // notifications are disabled in browsers for new resources by default
    getInitialValueInEffect: false,
  });
  const [keepWake, setKeepWake] = useLocalStorage<boolean>({
    key: STORAGE_KEY_KEEP_WAKE,
    defaultValue: CONFIG_IS_KEEP_WAKE_DEFAULT,
    getInitialValueInEffect: false,
  });
  const [isPlaySound, setPlaySound] = useLocalStorage<boolean>({
    key: STORAGE_KEY_PLAY_SOUND,
    defaultValue: CONFIG_IS_PLAY_SOUND_OPTION_DEFAULT,
    getInitialValueInEffect: false,
  });
  const [vibrate, setVibrate] = useLocalStorage<boolean>({
    key: STORAGE_KEY_VIBRATION,
    defaultValue: CONFIG_IS_VIBRATION_OPTION_DEFAULT,
    getInitialValueInEffect: false,
  });
  // end:settings

  const [notificationNeedPermissions, setNotificationNeedPermissions] =
    useState(permission.notification.default());
  const [notificationGranted, setNotificationGranted] = useState(
    permission.notification.granted()
  );
  const [notificationDenied, setNotificationDenied] = useState(
    permission.notification.denied()
  );
  const updateNotificationPermissions = () => {
    setNotificationNeedPermissions(permission.notification.default());
    setNotificationGranted(permission.notification.granted());
    setNotificationDenied(permission.notification.denied());
  };

  useEffect(() => {
    updateNotificationPermissions();
  }, [isNotificationsEnabled]);

  // this is needed in case user goes to system settings,
  // change notifications permissions and then get back to the app
  useEffect(() => {
    const callback = () => {
      updateNotificationPermissions();
    };

    navigator.permissions
      .query({ name: "notifications" })
      .then((permissionStatus) => {
        permissionStatus.addEventListener("change", callback);
      });
  }, []);

  useEffect(() => {
    // TODO: check on dark / light theme
    setTimeout(() => {
      matchThemeColor();
    }, UI_ANIMATION_SPEED * 1000); // TODO: should be done not by timeout, but on page transition end

    // smoothColorTransition(
    //   document
    //     .querySelector("#themeColorMeta")
    //     ?.getAttribute("content") as string,
    //   "#1a1b1e", // TODO: check on dark / light theme
    //   UI_ANIMATION_SPEED * 1000,
    //   (currentColor: string) => {
    //     setThemeColor(currentColor);
    //   }
    // );
  }, []);

  useEffect(() => {
    if (!myRef.current) return;

    const callback = () => {
      if (myRef.current && myRef.current?.scrollTop >= 100) {
        setScrolled((prev) => {
          if (!prev) {
            setThemeColor("#373a40"); // TODO: 56 make consistent
            // smoothColorTransition(
            //   document
            //     .querySelector("#themeColorMeta")
            //     ?.getAttribute("content") as string,
            //   "#25262b", // TODO: check on dark / light theme
            //   UI_ANIMATION_SPEED * 16 * 100,
            //   (currentColor: string) => {
            //     setThemeColor(currentColor);
            //   }
            // );
          }

          return true;
        });
      } else {
        setScrolled((prev) => {
          if (prev) {
            setThemeColor("#1a1b1e"); // TODO: 56 make consistent
            // smoothColorTransition(
            //   document
            //     .querySelector("#themeColorMeta")
            //     ?.getAttribute("content") as string,
            //   "#1a1b1e", // TODO: check on dark / light theme
            //   UI_ANIMATION_SPEED * 16 * 100,
            //   (currentColor: string) => {
            //     setThemeColor(currentColor);
            //   }
            // );
          }

          return false;
        });
      }
    };

    myRef.current.addEventListener("scroll", callback);
    return () => {
      if (!myRef.current) return;

      myRef.current.removeEventListener("scroll", callback);
    };
  }, [myRef.current]);

  const handleBackClick = () => {
    if (window && "history" in window && window.history.length > 1) {
      navigate(-1);
    } else {
      navigate("/?");
    }
  };

  const TRANSITION = {
    duration: UI_ANIMATION_SPEED,
    ease: cubicBezier(0.65, 0, 0.35, 1),
  };
  const pageMotion = {
    initial: {
      opacity: 0,
      y: "100svh",
    },
    animate: {
      opacity: 1,
      y: 0,
      transition: TRANSITION,
    },
    exit: {
      opacity: 0,
      y: "100svh",
      transition: TRANSITION,
    },
  };

  return (
    <motion.div
      initial="initial"
      animate="animate"
      exit="exit"
      variants={pageMotion}
      style={{
        position: "absolute",
        left: 0,
        right: 0,
        zIndex: 1,
        backgroundColor: "var(--mantine-color-body)",
      }}
    >
      <Group className={`${s.navbar} ${scrolled ? s.scrolled : ""}`}>
        {/* TODO: 87 implement handleBackClick */}
        <Link to="/?" className={s.actionIcon}>
          <IconArrowLeft size="32" color="var(--mantine-color-bright)" />
        </Link>
        <Title className={s.navTitle} c="bright" fw="400" my="md" size="h4">
          Settings
        </Title>
      </Group>
      <main className={s.main} ref={myRef}>
        <Box className={s.titleBox}>
          <Title className={s.title} c="bright" fw="400" mb="xl" pt="2rem">
            Settings
          </Title>
        </Box>
        <div className={s.snapPoint}>
          {" "}
          {/* <Title
            order={2}
            c={`${theme.primaryColor}.6`}
            fw="400"
            size="h6"
            mt="xl"
          >
            Time intervals
          </Title> */}
          <Group justify="space-between" my="lg">
            <Text c="bright" size="4.5rem">
              {CONFIG_FOCUS_TIME_MAP_TO_MILISECONDS[
                focusTimeKey !== undefined
                  ? focusTimeKey
                  : CONFIG_FOCUS_TIME_OPTION_DEFAULT
              ] /
                60 /
                1000}
              <span className={s.mark}>min</span>
            </Text>
          </Group>
          <Box mb="xl">
            <Slider
              size={16}
              thumbSize={24}
              min={0}
              max={8}
              step={1}
              value={focusTimeKey}
              onChange={setFocusTimeKey}
            />
            <Text>Focus time</Text>
          </Box>
          <Group justify="space-between" my="lg">
            <Text c="bright" size="4.5rem">
              {CONFIG_REST_TIME_MAP_TO_MILISECONDS[
                restTimeKey !== undefined
                  ? restTimeKey
                  : CONFIG_REST_TIME_OPTION_DEFAULT
              ] /
                60 /
                1000}
              <span className={s.mark}>min</span>
            </Text>
          </Group>
          <Box mb="xl">
            <Slider
              size={16}
              thumbSize={24}
              min={0}
              max={4}
              step={1}
              value={restTimeKey}
              onChange={setRestTimeKey}
            />
            <Text>Rest time</Text>
          </Box>
          {/* <Title
            order={2}
            c={`${theme.primaryColor}.6`}
            fw="400"
            size="h6"
            mt="xl"
          >
            Behaviour
          </Title> */}
          <Box my="3rem">
            {isAvailable.notification() && (
              <Box my="lg">
                <Switch
                  size="md"
                  defaultChecked
                  labelPosition="left"
                  disabled={notificationDenied}
                  checked={isNotificationsEnabled && notificationGranted}
                  onClick={() => {
                    if (notificationDenied) {
                      open();
                      setTimeout(close, 3000);
                    }
                  }}
                  onChange={async (e) => {
                    if (
                      e.currentTarget.checked &&
                      notificationNeedPermissions
                    ) {
                      let result;
                      try {
                        result = await Notification.requestPermission();
                      } finally {
                        if (result === "denied") {
                          open();
                          setTimeout(close, 5000);
                        }
                        setNotifications(result === "granted");
                        updateNotificationPermissions();
                      }
                    } else {
                      setNotifications(e.currentTarget.checked);
                    }
                  }}
                  label={
                    <Label
                      title="Notifications"
                      description={
                        <>
                          Recieve native notifications on timer events
                          {notificationDenied && (
                            <Text>
                              To enable notifications, first enable it in system
                              settings
                              {["android", "ios"].includes(os) || isIpadOS()
                                ? " and then reload the app"
                                : ""}
                            </Text>
                          )}
                        </>
                      }
                    />
                  }
                />
              </Box>
            )}
            <Box my="lg">
              <Switch
                size="md"
                defaultChecked
                labelPosition="left"
                checked={isPlaySound}
                onChange={(e) => {
                  setPlaySound(e.currentTarget.checked);
                }}
                label={
                  <Label
                    title="Play sound"
                    description="Play sound notification on timer end"
                  />
                }
              />
            </Box>
            {isAvailable.vibrate() &&
              Boolean(["ios", "android"].includes(os) || isIpadOS()) && (
                <Box my="lg">
                  <Switch
                    size="md"
                    defaultChecked
                    labelPosition="left"
                    checked={vibrate}
                    onChange={(e) => {
                      setVibrate(e.currentTarget.checked);
                    }}
                    label={
                      <Label
                        title="Vibration"
                        description="Vibrate device when timer ends"
                      />
                    }
                  />
                </Box>
              )}
            {isAvailable.wakeLock() && (
              <Box my="lg">
                <Switch
                  size="md"
                  defaultChecked
                  labelPosition="left"
                  checked={keepWake}
                  onChange={(e) => {
                    setKeepWake(e.currentTarget.checked);
                  }}
                  label={
                    <Label
                      title="Keep screen on"
                      description={
                        <>
                          Keep screen on while timer is runnning
                          {(["ios", "macos"].includes(os) || isIpadOS()) && (
                            <>
                              <br />⚠ May not work propperly in Safari and on
                              iOS.
                            </>
                          )}
                        </>
                      }
                    />
                  }
                />
              </Box>
            )}

            {/* <Box my="lg">
          <Switch
            size="md"
            defaultChecked
            labelPosition="left"
            label={
              <Label
                title="Sound notification"
                description="Ring the melody on timer ends"
              />
            }
          />
        </Box> */}
            {/* <Title order={2} c="red.6" fw="400" size="h6" mt="xl">
          Appearence
        </Title> */}
            {/* <Box my="lg">
          <NativeSelect
            size="md"
            variant="unstyled"
            label="Language"
            data={["System (English)", "English", "Ukrainian", "Polish"]}
            rightSection={<IconChevronRight />}
          />
        </Box> */}
            {/* <Group justify="space-between" my="lg">
          <label>Theme</label>
          <SegmentedControl
            data={[
              { value: "auto", label: <IconSettingsCheck /> },
              { value: "light", label: <IconSun /> },
              { value: "dark", label: <IconMoon /> },
            ]}
          />
        </Group> */}
            {/* <Box my="lg">
          <Switch
            size="md"
            defaultChecked
            labelPosition="left"
            label={
              <Label
                title="True black"
                description="Use true black color when dark theme is enabled. This may increase battery life"
              />
            }
          />
        </Box> */}
            {/* <Box my="lg">
          <Switch
            size="md"
            defaultChecked
            labelPosition="left"
            label={
              <Label
                title="Always on display (AOD)"
                description="Leave display on, but using minimum battery"
              />
            }
          />
        </Box> */}
          </Box>
          <Text c="dimmed" size="xs" mt="4rem" mb="2rem">
            Version: {process.env.REACT_APP_VERSION}
          </Text>
        </div>
      </main>
      <Modal
        opened={opened}
        onClose={close}
        withOverlay={false}
        withCloseButton={false}
        transitionProps={{ transition: "slide-up" }}
        style={{ "--_inner-align": "flex-end" }}
      >
        <Text>To enable notifications, first enable it in system settings</Text>
        <Text>And you must reload app after that</Text>
      </Modal>
    </motion.div>
  );
}
