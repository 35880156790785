import { BoxProps, Stack, Text } from "@mantine/core";
import { ReactNode } from "react";
import { JsxElement } from "typescript";

interface LabelProps extends BoxProps {
  title: string;
  description?: ReactNode;
}

export default function Label(props: LabelProps) {
  const { title, description, ...restProps } = props;
  return (
    <Stack gap={0} {...restProps}>
      <Text c="bright">{title}</Text>
      {description && <Text size="sm">{description}</Text>}
    </Stack>
  );
}
