import { Box, Stack, Text, Group, Button } from "@mantine/core";
import {
  IconArrowBigDown,
  IconArrowBigLeft,
  IconArrowBigRight,
  IconArrowBigUp,
  IconClick,
  IconX,
} from "@tabler/icons-react";
import s from "./Tutorial.module.css";
import { useLocalStorage } from "@mantine/hooks";
import { STORAGE_KEY_TUTORIAL_STEP } from "constants/storageKeys";
import {
  CONFIG_TUTORIAL_STEPS,
  CONFIG_TUTORIAL_STEP_DEFAULT,
} from "configs/appSettings";

interface TutorialProps {
  skipTutorialHandlerCallback: () => void;
}

export default function Tutorial(props: TutorialProps) {
  const { skipTutorialHandlerCallback } = props;

  const [tutorialStep, setTutorialStep] =
    useLocalStorage<CONFIG_TUTORIAL_STEPS>({
      key: STORAGE_KEY_TUTORIAL_STEP,
      defaultValue: CONFIG_TUTORIAL_STEP_DEFAULT,
      getInitialValueInEffect: false,
    });

  const skipTutorialHandler = () => {
    setTutorialStep(CONFIG_TUTORIAL_STEPS.OFF);
    skipTutorialHandlerCallback();
  };

  return (
    <Box className={s.tutorial}>
      <div className={s.tips}>
        <Stack className={s.text} align="center" c="white">
          {tutorialStep === CONFIG_TUTORIAL_STEPS.TO_REST && (
            <>
              <Text tt="uppercase">Swipe left for rest timer</Text>
              <IconArrowBigLeft
                size={48}
                stroke={1}
                className={s.swipeLeft}
                color="white"
              ></IconArrowBigLeft>
            </>
          )}
          {tutorialStep === CONFIG_TUTORIAL_STEPS.TO_FOCUS && (
            <>
              <Text tt="uppercase">Swipe right for focus timer</Text>
              <IconArrowBigRight
                size={48}
                stroke={1}
                className={s.swipeRight}
                color="white"
              ></IconArrowBigRight>
            </>
          )}
          {tutorialStep === CONFIG_TUTORIAL_STEPS.RESET && (
            <>
              <Text tt="uppercase">Swipe down to reset timer</Text>
              <IconArrowBigDown
                size={48}
                stroke={1}
                className={s.swipeDown}
                color="white"
              ></IconArrowBigDown>
            </>
          )}
          {tutorialStep === CONFIG_TUTORIAL_STEPS.TAP && (
            <>
              <Text tt="uppercase">Tap or click to start/pause timer</Text>
              <IconClick
                size={48}
                stroke={1}
                className={s.opacity}
                color="white"
              ></IconClick>
            </>
          )}
          {tutorialStep === CONFIG_TUTORIAL_STEPS.SHOW_HINTS && (
            <>
              <Text tt="uppercase">Swipe up to show control hints</Text>
              <IconArrowBigUp
                size={48}
                stroke={1}
                className={s.swipeUp}
                color="white"
              ></IconArrowBigUp>
            </>
          )}
        </Stack>
      </div>
      <Group className={s.skip} justify="center">
        <Button
          onClick={skipTutorialHandler}
          variant="transparent"
          tt="uppercase"
          c="white"
          leftSection={<IconX />}
        >
          Skip tutorial
        </Button>
      </Group>
    </Box>
  );
}
