// It is needed to stay consistent over the project (workers, react components, middleware etc)

// notification tags
export const TAG_KEEP_ON_SCREEN = "keepOnScreen";
export const TAG_FOCUS_TIMER_END = "focusTimerEnd";
export const TAG_REST_TIMER_END = "restTimerEnd";

// event names for workers
export const EVENT_STOP = "stop";
export const EVENT_START = "start";
export const EVENT_WORKER_TICK = "tick";
export const EVENT_WORKER_STOP = "workerStop";
