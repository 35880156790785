import { useEffect, RefObject } from "react";
import { Text, Modal, Group } from "@mantine/core";
import s from "./modal.module.css";
import {
  IconArrowBigDown,
  IconArrowBigLeft,
  IconArrowBigRight,
  IconArrowBigUp,
} from "@tabler/icons-react";

interface ControlHintModalProps {
  hintsRef: RefObject<HTMLDivElement>;
  hintsStatus: boolean;
  closeHintsHandler: () => void;
}

export default function ControlHintModal(props: ControlHintModalProps) {
  const { hintsRef, hintsStatus, closeHintsHandler } = props;

  return (
    <Modal
      title={<Text size="xs">Swipe control hints</Text>}
      opened={hintsStatus}
      onClose={closeHintsHandler}
      onClick={closeHintsHandler}
      size="auto"
      transitionProps={{ transition: "slide-up" }}
      radius="md"
      withOverlay={false}
      withCloseButton={true}
      className={s.modalTips}
      ref={hintsRef}
    >
      <Group>
        <Group gap="xs">
          <IconArrowBigRight size={24} stroke={1}></IconArrowBigRight>
          <Text size="xs">Focus Timer</Text>
        </Group>
        <Group gap="xs">
          <IconArrowBigLeft size={24} stroke={1}></IconArrowBigLeft>
          <Text size="xs">Rest Timer</Text>
        </Group>
      </Group>
      <Group mt="xs">
        <Group gap="xs">
          <IconArrowBigDown size={24} stroke={1}></IconArrowBigDown>
          <Text size="xs">Reset Timer</Text>
        </Group>
        <Group gap="xs">
          <IconArrowBigUp size={24} stroke={1}></IconArrowBigUp>
          <Text size="xs">Show Hints</Text>
        </Group>
      </Group>
    </Modal>
  );
}
