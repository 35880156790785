type RGBColor = {
  r: number;
  g: number;
  b: number;
};

function hexToRgb(hex: string): RGBColor {
  // Convert hex to RGB
  hex = hex.replace(/^#/, "");
  let bigint = parseInt(hex, 16);
  let r = (bigint >> 16) & 255;
  let g = (bigint >> 8) & 255;
  let b = bigint & 255;
  return { r, g, b };
}

function rgbToHex(r: number, g: number, b: number): string {
  // Convert RGB to hex
  return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
}

function smoothColorTransition(
  startColor: string,
  endColor: string,
  duration: number,
  callback: (currentColor: string) => void
): void {
  const startRGB: RGBColor = hexToRgb(startColor);
  const endRGB: RGBColor = hexToRgb(endColor);
  const totalSteps: number = duration / 16; // assuming 60 frames per second

  let currentStep: number = 0;

  const transitionInterval = setInterval(() => {
    const progress: number = currentStep / totalSteps;

    const currentRGB: RGBColor = {
      r: Math.round(startRGB.r + (endRGB.r - startRGB.r) * progress),
      g: Math.round(startRGB.g + (endRGB.g - startRGB.g) * progress),
      b: Math.round(startRGB.b + (endRGB.b - startRGB.b) * progress),
    };

    const currentHex: string = rgbToHex(
      currentRGB.r,
      currentRGB.g,
      currentRGB.b
    );

    callback(currentHex);

    currentStep++;

    if (currentStep >= totalSteps) {
      callback(endColor); // to ensure endColor is set
      clearInterval(transitionInterval);
    }
  }, 16);
}

export const useColorTransition = () => {
  return { smoothColorTransition };
};
