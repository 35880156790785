import s from "./404.module.css";
import { Title, Text } from "@mantine/core";
import { Link } from "react-router-dom";

export default function Page404() {
  return (
    <main className={s.main}>
      <Title size="96">404</Title>
      <Text>
        Start with <Link to="/">the main page</Link>
      </Text>
    </main>
  );
}
