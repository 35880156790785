import { Button, MantineProvider, createTheme } from "@mantine/core";
import "@mantine/core/styles.css";
import "globals.css";
import Page404 from "pages/404";
import Home from "pages/home";
import Settings from "pages/settings";
import { Outlet, Route, Routes, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import { UI_ANIMATION_SPEED } from "configs/appSettings";

const theme = createTheme({
  primaryColor: "red",
  primaryShade: 7,
  focusRing: "never",
  components: {
    Button: Button.extend({
      vars: (theme, props) => {
        if (props.variant === "transparent") {
          return {
            root: {
              "--button-color": "var(--mantine-color-text)",
            },
          };
        }
        return { root: {} };
      },
    }),
  },
});

export default function App() {
  const location = useLocation();

  return (
    <MantineProvider theme={theme} defaultColorScheme="auto">
      <AnimatePresence>
        <Routes location={location} key={location.pathname}>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="settings" element={<Settings />} />
            <Route path="*" element={<Page404 />} />
          </Route>
        </Routes>
      </AnimatePresence>
    </MantineProvider>
  );
}

function Layout() {
  return (
    <>
      <div
        style={
          {
            "--animation-speed": `${UI_ANIMATION_SPEED}s`,
          } as React.CSSProperties
        }
      >
        <Outlet />
      </div>
    </>
  );
}
