import { useState, useEffect, useRef, SetStateAction, Dispatch } from "react";
import { shortName } from "../configs/meta";
import { Text, RingProgress, Box } from "@mantine/core";
import s from "./home.module.css";
import {
  IconPlayerPauseFilled,
  IconPlayerPlayFilled,
  IconSettings2,
} from "@tabler/icons-react";
import { useScreenLock } from "utils/useScreenLock";
import isAvailable from "utils/isAvailable";
import checkPermission from "utils/checkPermission";
import { useDisclosure, useLocalStorage, useOs } from "@mantine/hooks";
import {
  EVENT_START,
  EVENT_STOP,
  EVENT_WORKER_TICK,
  EVENT_WORKER_STOP,
} from "constants/commonTagEventNames";
import { LEFT, RIGHT, UP, DOWN, useSwipeable } from "react-swipeable";
import ControlHintModal from "components/modals/ControlHint";
import InstalliOSModal, { isIpadOS } from "components/modals/InstalliOS";
import InstallNativeModal from "components/modals/InstallNative";
import NotificationPermissionModal from "components/modals/NotificationPermission";
import {
  STORAGE_KEY_FOCUS_TIME,
  STORAGE_KEY_KEEP_WAKE,
  STORAGE_KEY_MODAL_INSTALL_IOS_TIMESTAMP,
  STORAGE_KEY_MODAL_INSTALL_NATIVE_TIMESTAMP,
  STORAGE_KEY_MODAL_NOTIFICATION_SHOWN,
  STORAGE_KEY_NOTIFICATIONS,
  STORAGE_KEY_PLAY_SOUND,
  STORAGE_KEY_REST_TIME,
  STORAGE_KEY_TUTORIAL_STEP,
  STORAGE_KEY_VIBRATION,
} from "constants/storageKeys";
import useThemeColor from "utils/useThemeColor";
import {
  UI_ANIMATION_SPEED,
  CONFIG_FOCUS_TIME_MAP_TO_MILISECONDS,
  CONFIG_FOCUS_TIME_OPTION_ARRAY,
  CONFIG_FOCUS_TIME_OPTION_DEFAULT,
  CONFIG_IS_KEEP_WAKE_DEFAULT,
  CONFIG_IS_PLAY_SOUND_OPTION_DEFAULT,
  CONFIG_IS_VIBRATION_OPTION_DEFAULT,
  CONFIG_REST_TIME_MAP_TO_MILISECONDS,
  CONFIG_REST_TIME_OPTION_ARRAY,
  CONFIG_REST_TIME_OPTION_DEFAULT,
  CONFIG_TUTORIAL_STEPS,
  CONFIG_TUTORIAL_STEP_DEFAULT,
  SHOW_INSTALL_MODAL_ONCE_A,
  TimerType,
  MAP_THEME_COLOR,
  THEME_COLOR,
  MAP_THEME_COLOR_MUTED,
} from "configs/appSettings";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import {
  ROUTE_PARAM_AUTOSTART,
  ROUTE_PARAM_TYPE,
} from "constants/routingSettings";
import { firebaseApp, getTokenCustom } from "./../firebase";
import {
  getFirestore,
  doc,
  setDoc,
  updateDoc,
  deleteField,
  getDoc,
} from "firebase/firestore";
import { FBCOL_USER_ALARMS } from "constants/firestore";
import { motion } from "framer-motion";
import Tutorial from "components/Tutorial";

declare global {
  interface Window {
    documentPictureInPicture: any;
  }
}

enum TimerState {
  INITIAL,
  ACTIVE,
  PAUSED,
}

// map timerType to instances
const MAP_CLASS = {
  [TimerType.FOCUS]: s.focus,
  [TimerType.REST]: s.rest,
};
// end:map timerType to instances

// helpers
const makeTimeOutOfSeconds = (secondsPassed: number, timer: number) => {
  const str_pad_left = (string: number) => {
    return (new Array(2 + 1).join("0") + string).slice(-2);
  };
  let secondsLeft = timer / 1000 - secondsPassed;
  let minutes = Math.floor(secondsLeft / 60);
  let seconds = secondsLeft - minutes * 60;

  return str_pad_left(minutes) + ":" + str_pad_left(seconds);
};

const animateTimer = (
  secondsStart: number,
  callback: Dispatch<SetStateAction<number>>
) => {
  const ANIMATION_DURATION: number = 0.5 * 1000; // ms
  const UPDATE_INTERVAL: number = 1000 / 60; // ms
  const timeStart: number = Date.now();

  const interval = setInterval(() => {
    const timeFromStart: number = Date.now() - timeStart;
    const progress: number = Math.min(timeFromStart / ANIMATION_DURATION, 1); // 1 is 100%
    const secondsCurrent: number = Math.max(
      secondsStart - Math.floor(easeOutCubic(progress) * secondsStart),
      0
    ); // 0 is the lowest value

    callback(secondsCurrent);

    if (secondsCurrent === 0) {
      clearInterval(interval);
    }
  }, UPDATE_INTERVAL);
};

function easeOutCubic(x: number): number {
  return 1 - Math.pow(1 - x, 3);
}
// end:helpers

export default function Home() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const os = useOs();

  const soundEffect: HTMLAudioElement = new Audio();
  soundEffect.autoplay = true;

  const _getDefaultTimerType = () => {
    // TODO: 88 implement _getTimerBySearchParam like in _getTimeByTimerType
    const timerTypeFromUrl = Number(searchParams.get(ROUTE_PARAM_TYPE));

    if (timerTypeFromUrl && timerTypeFromUrl in TimerType) {
      return timerTypeFromUrl;
    }

    return TimerType.FOCUS;
  };

  const { setThemeColor } = useThemeColor();

  const setThemeColorAfterAnimation = (color: string) => {
    setTimeout(() => setThemeColor(color), UI_ANIMATION_SPEED * 1000);
  };

  // state
  const [seconds, setSeconds] = useState<number>(0); // current seconds elapsed
  const [timerState, setTimerState] = useState<TimerState>(TimerState.INITIAL); // current timer state
  const [timerType, setTimerType] = useState<TimerType>(_getDefaultTimerType()); // current timer type
  const [userToken, setUserToken] = useState<string | null>(null);

  const _getTimeByTimerType = (timerType: TimerType): number => {
    switch (timerType) {
      case TimerType.FOCUS:
        return CONFIG_FOCUS_TIME_MAP_TO_MILISECONDS[focusTimeKey];
      case TimerType.REST:
        return CONFIG_REST_TIME_MAP_TO_MILISECONDS[restTimeKey];
      default:
        return 0;
    }
  };

  const [modalStatus, { open: openModal, close: closeModal }] =
    useDisclosure(false);
  const [
    installModalStatus,
    { open: openInstallModal, close: closeInstallModal },
  ] = useDisclosure(false);
  const [
    installiOSModalStatus,
    { open: openInstalliOSModal, close: closeInstalliOSModal },
  ] = useDisclosure(false);
  const [hintsStatus, { open: openHints, close: closeHints }] =
    useDisclosure(false);
  const ringProgressRef = useRef<HTMLDivElement>(null);
  const playRef = useRef<HTMLDivElement>(null);
  const pauseRef = useRef<HTMLDivElement>(null);
  const hintsRef = useRef<HTMLDivElement>(null);
  // end:state

  // start:settings
  const [isNotificationsEnabled, setNotifications] = useLocalStorage<boolean>({
    key: STORAGE_KEY_NOTIFICATIONS,
    defaultValue: false,
    getInitialValueInEffect: false,
  });
  const [keepWake] = useLocalStorage<boolean>({
    key: STORAGE_KEY_KEEP_WAKE,
    defaultValue: CONFIG_IS_KEEP_WAKE_DEFAULT,
    getInitialValueInEffect: false,
  });
  const [isPlaySound] = useLocalStorage<boolean>({
    key: STORAGE_KEY_PLAY_SOUND,
    defaultValue: CONFIG_IS_PLAY_SOUND_OPTION_DEFAULT,
    getInitialValueInEffect: false,
  });
  const [focusTimeKey] = useLocalStorage<CONFIG_FOCUS_TIME_OPTION_ARRAY>({
    key: STORAGE_KEY_FOCUS_TIME,
    defaultValue: CONFIG_FOCUS_TIME_OPTION_DEFAULT,
    getInitialValueInEffect: false,
  });
  const [restTimeKey] = useLocalStorage<CONFIG_REST_TIME_OPTION_ARRAY>({
    key: STORAGE_KEY_REST_TIME,
    defaultValue: CONFIG_REST_TIME_OPTION_DEFAULT,
    getInitialValueInEffect: false,
  });
  const [vibrate] = useLocalStorage<boolean>({
    key: STORAGE_KEY_VIBRATION,
    defaultValue: CONFIG_IS_VIBRATION_OPTION_DEFAULT,
    getInitialValueInEffect: false,
  });
  const [tutorialStep, setTutorialStep] =
    useLocalStorage<CONFIG_TUTORIAL_STEPS>({
      key: STORAGE_KEY_TUTORIAL_STEP,
      defaultValue: CONFIG_TUTORIAL_STEP_DEFAULT,
      getInitialValueInEffect: false,
    });
  // end:settings

  // hooks
  const workerRef = useRef<Worker>();
  const [
    isNotificationPermissionModalShown,
    setNotificationPermissionModalValue,
  ] = useLocalStorage<boolean>({
    key: STORAGE_KEY_MODAL_NOTIFICATION_SHOWN,
    defaultValue: false,
    getInitialValueInEffect: false,
  });
  const [timestampInstallNativeModal, setTimestampInstallNativeModal] =
    useLocalStorage<number>({
      key: STORAGE_KEY_MODAL_INSTALL_NATIVE_TIMESTAMP,
      defaultValue: 0,
      getInitialValueInEffect: false,
    });
  const [timestampInstalliOSModal, setTimestampInstalliOSModal] =
    useLocalStorage<number>({
      key: STORAGE_KEY_MODAL_INSTALL_IOS_TIMESTAMP,
      defaultValue: 0,
      getInitialValueInEffect: false,
    });

  const screenLock = useScreenLock();
  // const notify = useNotifications();

  const swipeHandlers = useSwipeable({
    trackMouse: true,
    onSwiping: ({ dir, deltaX, deltaY }) => {
      const maxDelta = 50;
      ringProgressRef.current &&
        (ringProgressRef.current.style.transition = "none");
      if (dir === DOWN) {
        const delta = (deltaY * maxDelta) / document.body.clientHeight;
        ringProgressRef.current &&
          (ringProgressRef.current.style.transform = `translateY(${delta}px)`);
      }
      if (dir === LEFT || dir === RIGHT) {
        const delta = (deltaX * maxDelta) / document.body.clientWidth;
        ringProgressRef.current &&
          (ringProgressRef.current.style.transform = `translateX(${delta}px)`);
      }
    },
    onSwiped: (e) => {
      // TODO: move to css styles or refactor in other way
      ringProgressRef.current && (ringProgressRef.current.style.transform = "");
      ringProgressRef.current &&
        (ringProgressRef.current.style.transition =
          "all .4s cubic-bezier(0.22, 1, 0.36, 1)");
      switch (e.dir) {
        case LEFT:
          if (timerType === TimerType.REST) return null;
          if (
            tutorialStep !== CONFIG_TUTORIAL_STEPS.OFF &&
            tutorialStep !== CONFIG_TUTORIAL_STEPS.TO_REST
          )
            return null;

          timer.switch(TimerType.REST);
          if (tutorialStep !== CONFIG_TUTORIAL_STEPS.OFF) {
            setTutorialStep(CONFIG_TUTORIAL_STEPS.TO_FOCUS);
          }
          break;
        case RIGHT:
          if (timerType === TimerType.FOCUS) return null;
          if (
            tutorialStep !== CONFIG_TUTORIAL_STEPS.OFF &&
            tutorialStep !== CONFIG_TUTORIAL_STEPS.TO_FOCUS
          )
            return null;

          timer.switch(TimerType.FOCUS);
          if (tutorialStep !== CONFIG_TUTORIAL_STEPS.OFF) {
            setTutorialStep(CONFIG_TUTORIAL_STEPS.RESET);
            // TODO: think of workaround
            setTimeout(() => {
              setSeconds(15 * 60);
            }, 32);
          }
          break;
        case DOWN:
          if (
            tutorialStep !== CONFIG_TUTORIAL_STEPS.OFF &&
            tutorialStep !== CONFIG_TUTORIAL_STEPS.RESET
          )
            return null;
          if (tutorialStep !== CONFIG_TUTORIAL_STEPS.OFF) {
            setTutorialStep(CONFIG_TUTORIAL_STEPS.TAP);
          }
          setSeconds((secs) => {
            timer.reset();
            animateTimer(secs, setSeconds);
            return secs;
          });
          break;
        case UP:
          if (
            tutorialStep !== CONFIG_TUTORIAL_STEPS.OFF &&
            tutorialStep !== CONFIG_TUTORIAL_STEPS.SHOW_HINTS
          )
            return null;

          if (tutorialStep !== CONFIG_TUTORIAL_STEPS.OFF) {
            setSeconds((secs) => {
              timer.reset();
              animateTimer(secs, setSeconds);
              return secs;
            });
            setTutorialStep(CONFIG_TUTORIAL_STEPS.OFF);
            setThemeColor(MAP_THEME_COLOR[timerType]);
          }
          if (hintsStatus) {
            hintsRef.current?.classList.add(s.animate);
            // TODO: rewrite logic, tie on animationend event or look up in animate.css lib
            setTimeout(() => {
              hintsRef.current?.classList.remove(s.animate);
            }, 1000);
          } else {
            openHints();
          }
          break;
      }
    },
    delta: 20,
  });

  useEffect(
    /**
     * Create a worker for setInterval in separate stream on first launch
     */
    () => {
      workerRef.current = new Worker(new URL("../worker.js", import.meta.url));
      return () => {
        screenLock?.unlock();
        workerRef.current?.terminate();
      };
    },
    []
  );

  useEffect(
    /**
     * Handle `message` event cases from an to service worker
     */
    () => {
      const callback = (e: MessageEvent) => {
        const { name, time } = e.data;

        switch (name) {
          case EVENT_WORKER_TICK:
            doEverySecond(Number(time));
            break;
          case EVENT_WORKER_STOP:
            // TODO: 18 DRY

            timer.switch(
              timerType === TimerType.FOCUS ? TimerType.REST : TimerType.FOCUS
            );
            break;
        }
      };
      // animateTimer(_getTimeByTimerType(timerType), setSeconds);
      setSeconds(0);
      workerRef.current?.addEventListener("message", callback);
      return () => {
        workerRef.current?.removeEventListener("message", callback);
        workerRef.current?.postMessage({ name: EVENT_STOP });
      };
    },
    [timerType]
  );

  useEffect(
    /**
     * In case:
     *  + notification is enabled and permissions granted
     *  + userToken is granted
     *  + app becomes hidden
     *  + timer is active
     * set record in firestore to notify user on timestamp
     */
    () => {
      if (!checkPermission.notification.granted()) return;

      const db = getFirestore(firebaseApp);
      getTokenCustom(setUserToken);

      const callback = async () => {
        if (userToken === null) return;

        if (
          !document.hidden ||
          !isNotificationsEnabled ||
          timerState !== TimerState.ACTIVE
        )
          return;

        // TODO: DRY: move secondsLeft to one function
        const secondsLeft = _getTimeByTimerType(timerType) / 1000 - seconds;
        const timestamp = Date.now() + secondsLeft * 1000;

        await setDoc(doc(db, FBCOL_USER_ALARMS, userToken), {
          timestamp,
        });
      };

      document.addEventListener("visibilitychange", callback);

      return () => {
        document.removeEventListener("visibilitychange", callback);
      };
    },
    [userToken, timerState, seconds, timerType, isNotificationsEnabled]
  );

  useEffect(
    /**
     * When app is visible and timer pauses,
     * check existence of user alarm doc cor current user
     * and if exist, delete it
     */
    () => {
      if (userToken === null) return;

      if (document.hidden || timerState === TimerState.ACTIVE) return;

      const db = getFirestore(firebaseApp);
      const docRef = doc(db, FBCOL_USER_ALARMS, userToken);

      getDoc(docRef).then((docSnap) => {
        if (docSnap.exists()) {
          updateDoc(docRef, {
            timestamp: deleteField(),
          });
        }
      });
    },
    [timerState, userToken]
  );

  // TODO: 88 after migrating to react router check if can be moved to component body from useEffect
  useEffect(
    /**
     * Handle searchParams (the timer type and autostart)
     */
    () => {
      if (searchParams.get(ROUTE_PARAM_TYPE)) {
        const timerTypeFromUrl = Number(searchParams.get(ROUTE_PARAM_TYPE));
        if (timerTypeFromUrl in MAP_THEME_COLOR) {
          setThemeColorAfterAnimation(
            MAP_THEME_COLOR[timerTypeFromUrl as TimerType]
          );
        }
      } else {
        setThemeColor(THEME_COLOR);

        if (tutorialStep !== CONFIG_TUTORIAL_STEPS.OFF) {
          setTimeout(() => {
            setThemeColor(MAP_THEME_COLOR_MUTED[timerType]);
          }, 200 * 2 + 400 - 200); // TODO: make consistent with animation duration and delay
        }
      }
      // autostart timer, but after component load fully
      if (searchParams.get(ROUTE_PARAM_AUTOSTART) !== null) {
        mainClickHandler(); // TODO: remove with timer.play()
      }
    },
    []
  );
  // end: hooks

  const doEverySecond = (timeElapsed: number) => {
    setSeconds((sec) => {
      if (sec >= _getTimeByTimerType(timerType) / 1000) {
        const newTimerType: TimerType =
          timerType === TimerType.FOCUS ? TimerType.REST : TimerType.FOCUS;
        // TODO: 18 DRY
        animateTimer(_getTimeByTimerType(newTimerType), setSeconds);
        //timer.switch(newTimerType);
        return sec;
      }
      // let ns = sec + 1;
      let ns = timeElapsed;
      if (ns === _getTimeByTimerType(timerType) / 1000) {
        // send notification about timer being finished
        // disable notification from front as it works from backend for now
        // isNotificationsEnabled &&
        //   notify.send({
        //     title: "Countdown is over",
        //     body:
        //       timerType === TimerType.FOCUS
        //         ? "Take a rest"
        //         : "Time to focus",
        //     renotify: true,
        //     tag:
        //       timerType === TimerType.FOCUS
        //         ? TAG_FOCUS_TIMER_END
        //         : TAG_REST_TIMER_END,
        //   });
        // ... and do the vibration (works only when pwa tab is active)
        // iOS doesn't have vibrate in navigator, see https://caniuse.com/mdn-api_navigator_vibrate
        isAvailable.vibrate() &&
          vibrate &&
          Boolean(["ios", "android"].includes(os) || isIpadOS()) &&
          navigator.vibrate([
            33, 51, 75, 113, 170, 255, 0, 38, 62, 100, 160, 255,
          ]);
        if (isPlaySound) {
          soundEffect.src = "/alarm.mp3";
          soundEffect.play();
        }
        screenLock?.unlock();
      }
      return ns;
    });
  };

  // handlers
  const mainClickHandler = () => {
    soundEffect.src =
      "data:audio/mpeg;base64,SUQzBAAAAAABEVRYWFgAAAAtAAADY29tbWVudABCaWdTb3VuZEJhbmsuY29tIC8gTGFTb25vdGhlcXVlLm9yZwBURU5DAAAAHQAAA1N3aXRjaCBQbHVzIMKpIE5DSCBTb2Z0d2FyZQBUSVQyAAAABgAAAzIyMzUAVFNTRQAAAA8AAANMYXZmNTcuODMuMTAwAAAAAAAAAAAAAAD/80DEAAAAA0gAAAAATEFNRTMuMTAwVVVVVVVVVVVVVUxBTUUzLjEwMFVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQsRbAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQMSkAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVV";
    {
      // if ("documentPictureInPicture" in window) {
      //   window.documentPictureInPicture
      //     .requestWindow({
      //       width: 300,
      //       height: 300,
      //     })
      //     .then(
      //       (result: {
      //         document: {
      //           body: {
      //             appendChild(style: HTMLStyleElement): unknown;
      //             append(arg0: string): unknown;
      //           };
      //         };
      //       }) => {
      //         let style = document.createElement("style");
      //         style.innerHTML = "* { background: transparent; color: red; }";
      //         result.document.body.appendChild(style);
      //         result.document.body.append("Lorem ipsum");
      //       }
      //     );
      // }
    }
    if (
      tutorialStep !== CONFIG_TUTORIAL_STEPS.OFF &&
      tutorialStep !== CONFIG_TUTORIAL_STEPS.TAP
    )
      return null;

    if (tutorialStep !== CONFIG_TUTORIAL_STEPS.OFF) {
      setTutorialStep(CONFIG_TUTORIAL_STEPS.SHOW_HINTS);
    }

    closeHintsHandler();

    if (
      tutorialStep === CONFIG_TUTORIAL_STEPS.OFF &&
      isAvailable.notification() &&
      !checkPermission.notification() &&
      !isNotificationPermissionModalShown
    ) {
      openModal();
      return;
    }

    timer.toggle();

    // play/pause animations
    playRef.current?.classList.toggle(
      s.active,
      timerState !== TimerState.ACTIVE
    );
    pauseRef.current?.classList.toggle(
      s.active,
      timerState === TimerState.ACTIVE
    );
  };

  const dismissNotificationPermissionHandler = () => {
    closeModal();
    setNotificationPermissionModalValue(true);
    timer.play();
  };

  const allowNotificationPermissionHandler = async () => {
    closeModal();
    try {
      const result = await Notification.requestPermission();
      if (result === "granted") {
        setNotifications(true);
      }
    } finally {
      timer.play();
      setNotificationPermissionModalValue(true);
    }
  };

  const closeInstallModalHandler = () => {
    closeInstallModal();
    setTimestampInstallNativeModal(Date.now());
  };

  const closeInstalliOSModalHandler = () => {
    closeInstalliOSModal();
    setTimestampInstalliOSModal(Date.now());
  };

  const closeHintsHandler = () => {
    closeHints();
  };
  // end:handlers

  const timer = {
    play() {
      pauseRef.current?.classList.remove(s.active); // stop pause animation
      setTimerState(TimerState.ACTIVE);
      if (keepWake) {
        screenLock?.lock();
      }
      workerRef.current?.postMessage({
        name: EVENT_START,
        duration: _getTimeByTimerType(timerType) / 1000,
        elapsed: seconds,
      });
    },
    pause() {
      playRef.current?.classList.remove(s.active); // stop play animation
      setTimerState(TimerState.PAUSED);
      screenLock?.unlock();
      workerRef.current?.postMessage({ name: EVENT_STOP });
    },
    reset() {
      playRef.current?.classList.remove(s.active); // stop play animation
      setTimerState(TimerState.INITIAL);
      screenLock?.unlock();
      workerRef.current?.postMessage({ name: EVENT_STOP });
    },
    /** performs timer.play() or timer.pause() */
    toggle() {
      timerState === TimerState.ACTIVE ? this.pause() : this.play();
    },
    switch(timer: TimerType) {
      this.reset();

      timer === TimerType.REST &&
        navigate(`/?${ROUTE_PARAM_TYPE}=${TimerType.REST}`);
      timer === TimerType.FOCUS && navigate("/");
      setTimerType(timer);

      const themeColor =
        tutorialStep !== CONFIG_TUTORIAL_STEPS.OFF
          ? MAP_THEME_COLOR_MUTED[timer]
          : MAP_THEME_COLOR[timer];

      setThemeColorAfterAnimation(themeColor);
    },
  };

  const pageMotion = {
    initial: { opacity: 0 },
    animate: { opacity: 1, transition: { duration: 0 } },
    exit: {
      opacity: 0,
      transition: { duration: 0, delay: UI_ANIMATION_SPEED },
    },
  };

  return (
    <motion.div
      initial="initial"
      animate="animate"
      exit="exit"
      variants={pageMotion}
      style={{ position: "absolute", top: 0 }}
    >
      <Box
        className={`${s.menu} ${
          timerState === TimerState.ACTIVE ||
          tutorialStep !== CONFIG_TUTORIAL_STEPS.OFF
            ? s.hidden
            : ""
        }`}
      >
        <Link to="/settings" className={s.actionIcon}>
          <IconSettings2 size="32" color="white"></IconSettings2>
        </Link>
      </Box>
      <main
        {...swipeHandlers}
        className={`${s.main} ${MAP_CLASS[timerType]}`}
        onClick={mainClickHandler}
      >
        <Box ref={playRef} className={s.playBox}>
          <IconPlayerPlayFilled className={s.play} size={128} />
        </Box>
        <Box ref={pauseRef} className={s.pauseBox}>
          <IconPlayerPauseFilled className={s.pause} size={128} />
        </Box>
        <RingProgress
          ref={ringProgressRef}
          size={300}
          thickness={10}
          rootColor="transparent"
          className={s.ring}
          sections={[
            {
              value:
                100 - (seconds * 1000 * 100) / _getTimeByTimerType(timerType),
              color: "white",
            },
          ]}
          label={
            <Text
              className={s.ringText}
              c="white"
              fw={"bold"}
              ta="center"
              size="3rem"
            >
              {makeTimeOutOfSeconds(seconds, _getTimeByTimerType(timerType))}
            </Text>
          }
        />
      </main>
      <NotificationPermissionModal
        modalStatus={modalStatus}
        dismissNotificationPermissionHandler={
          dismissNotificationPermissionHandler
        }
        allowNotificationPermissionHandler={allowNotificationPermissionHandler}
      />
      <ControlHintModal
        hintsRef={hintsRef}
        hintsStatus={hintsStatus}
        closeHintsHandler={closeHintsHandler}
      />
      <InstallNativeModal
        isInstallModalShown={
          Number(timestampInstallNativeModal) + SHOW_INSTALL_MODAL_ONCE_A >
          Date.now()
        }
        installModalStatus={
          installModalStatus && timerState !== TimerState.ACTIVE
        }
        openInstallModal={openInstallModal}
        closeInstallModalHandler={closeInstallModalHandler}
      />
      <InstalliOSModal
        installiOSModalStatus={
          installiOSModalStatus && timerState !== TimerState.ACTIVE
        }
        isInstalliOSModalShown={
          Number(timestampInstalliOSModal) + SHOW_INSTALL_MODAL_ONCE_A >
          Date.now()
        }
        openInstalliOSModal={openInstalliOSModal}
        closeInstalliOSModalHandler={closeInstalliOSModalHandler}
      />
      {tutorialStep !== CONFIG_TUTORIAL_STEPS.OFF && (
        <Tutorial
          skipTutorialHandlerCallback={() => {
            setThemeColor(MAP_THEME_COLOR[timerType]);
          }}
        />
      )}
    </motion.div>
  );
}
