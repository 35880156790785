import { Text, Modal, Button, Group, useMantineTheme } from "@mantine/core";

interface NotificationPermissionModalProps {
  modalStatus: boolean;
  dismissNotificationPermissionHandler: () => void;
  allowNotificationPermissionHandler: () => void;
}

export default function NotificationPermissionModal(
  props: NotificationPermissionModalProps
) {
  const {
    modalStatus,
    dismissNotificationPermissionHandler,
    allowNotificationPermissionHandler,
  } = props;

  const theme = useMantineTheme();

  return (
    <Modal
      size="sm"
      padding="lg"
      opened={modalStatus}
      transitionProps={{ transition: "slide-up" }}
      onClose={dismissNotificationPermissionHandler}
      radius="md"
      title={
        <Text fw="500" size="xl">
          Permission required
        </Text>
      }
      overlayProps={{ backgroundOpacity: 0 }}
      withCloseButton={false}
      centered
    >
      <Text>
        You will need notification permission granted for app to work properly.
      </Text>
      <Group mt="lg">
        <Button
          size="md"
          variant="gradient"
          fullWidth
          gradient={{
            from: `${theme.primaryColor}.6`,
            to: `${theme.primaryColor}.8`,
            deg: 120,
          }}
          onClick={allowNotificationPermissionHandler}
        >
          Allow
        </Button>
        <Button
          size="md"
          onClick={dismissNotificationPermissionHandler}
          variant="default"
          fullWidth
        >
          Dismiss
        </Button>
      </Group>
    </Modal>
  );
}
