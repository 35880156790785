/**
 * This needs to be consistent throughout all project
 */

export const STORAGE_KEY_FOCUS_TIME = "config-focusTime";
export const STORAGE_KEY_REST_TIME = "config-restTime";
export const STORAGE_KEY_NOTIFICATIONS = "config-notifications";
export const STORAGE_KEY_KEEP_WAKE = "config-keepWake";
export const STORAGE_KEY_PLAY_SOUND = "config-playSound";
export const STORAGE_KEY_VIBRATION = "config-vibrate";
export const STORAGE_KEY_TUTORIAL_STEP = "config-tutorialStep";

export const STORAGE_KEY_MODAL_NOTIFICATION_SHOWN =
  "isNotificationPermissionModalShown";
export const STORAGE_KEY_MODAL_INSTALL_NATIVE_TIMESTAMP =
  "timestampInstallNativeModal";
export const STORAGE_KEY_MODAL_INSTALL_IOS_TIMESTAMP =
  "timestampInstalliOSModal";
