import { useState, useEffect } from "react";

import {
  Text,
  Modal,
  Button,
  Group,
  ModalCloseButton,
  useMantineTheme,
} from "@mantine/core";
import s from "./modal.module.css";
import { IconDownload } from "@tabler/icons-react";

interface BeforeInstallPromptEvent extends Event {
  prompt(): Promise<void>;
}
declare global {
  interface WindowEventMap {
    beforeinstallprompt: BeforeInstallPromptEvent;
  }
  interface Window {
    workbox: any;
  }
}

interface InstallNativeModalProps {
  isInstallModalShown: boolean;
  installModalStatus: boolean;
  openInstallModal: () => void;
  closeInstallModalHandler: () => void;
}

export default function InstallNativeModal(props: InstallNativeModalProps) {
  const {
    isInstallModalShown,
    installModalStatus,
    openInstallModal,
    closeInstallModalHandler,
  } = props;

  const theme = useMantineTheme();

  const [installPrompt, setInstallPrompt] =
    useState<null | BeforeInstallPromptEvent>(null);

  useEffect(() => {
    const callback = (event: BeforeInstallPromptEvent) => {
      event.preventDefault();
      setInstallPrompt(event);

      !isInstallModalShown && setTimeout(openInstallModal, 2000);
    };
    window.addEventListener("beforeinstallprompt", callback);
    return () => {
      window.removeEventListener("beforeinstallprompt", callback);
    };
  }, [isInstallModalShown]);

  return (
    <Modal
      opened={installModalStatus}
      onClose={closeInstallModalHandler}
      size="sm"
      transitionProps={{ transition: "slide-down" }}
      radius="md"
      withOverlay={false}
      withCloseButton={false}
      yOffset="1rem"
    >
      <Group align="flex-start" wrap="nowrap" gap="xs">
        <img className={s.modalInstallIcon} src="./icons/192.png" />
        <Text size="sm">
          You can install a Pomomini app on your device and use it as standalone
          app.
          <Group mt="md" gap="xs">
            <Button
              variant="gradient"
              gradient={{
                from: `${theme.primaryColor}.6`,
                to: `${theme.primaryColor}.8`,
                deg: 120,
              }}
              onClick={async () => {
                closeInstallModalHandler();
                installPrompt?.prompt();
              }}
              rightSection={<IconDownload size={16} stroke={2.5} />}
            >
              Install
            </Button>
            <Button variant="transparent" onClick={closeInstallModalHandler}>
              Dismiss
            </Button>
          </Group>
        </Text>
        <ModalCloseButton onClick={closeInstallModalHandler}></ModalCloseButton>
      </Group>
    </Modal>
  );
}
