/**
 * Check of basic browser APIs for PWA to work properlys
 */

const notification = () => "Notification" in window;
const wakeLock = () => "wakeLock" in navigator;
const vibrate = () => "vibrate" in navigator;

export default {
  notification,
  wakeLock,
  vibrate,
};
