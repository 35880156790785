import { getMessaging, getToken, onMessage } from "firebase/messaging";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
export const firebaseConfig = {
  apiKey: "AIzaSyDpxj7Wg9Y8sAt-Lfl_H4MtcUQ_KMDJ5zc",
  authDomain: "pomomini.com",
  projectId: "pomomini",
  storageBucket: "pomomini.appspot.com",
  messagingSenderId: "474836454221",
  appId: "1:474836454221:web:985d7503c4caca69cd8504",
  measurementId: "G-WWXR03BMGC",
};

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

// to use it in code
// export const onMessageListener = () =>
//   new Promise((resolve) => {
//     onMessage(messaging, (payload) => {
//       console.log("payload:", payload);
//       resolve(payload);
//     });
//   });

export const getTokenCustom = (setTokenFound) => {
  return getToken(messaging, {
    vapidKey:
      "BGQWm6vHuZ3pwrJLbkn1OiZc707lDUmpnSLv7yEmiJuWiSfXPRmyPRFljJaJ0Ap_NDHtIjOMyzE9pRjDgoTx26k",
  })
    .then((currentToken) => {
      if (currentToken) {
        // console.log("current token for client: ", currentToken);
        setTokenFound(currentToken);
        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
      } else {
        console.log(
          "No registration token available. Request permission to generate one."
        );
        setTokenFound(false);
        // shows on the UI that permission is required
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
      // catch error while creating client token
    });
};
