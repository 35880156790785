/** constants */

export enum TimerType {
  FOCUS,
  REST,
}

export enum CONFIG_FOCUS_TIME_OPTION_ARRAY {
  MIN_20,
  MIN_25,
  MIN_30,
  MIN_35,
  MIN_40,
  MIN_45,
  MIN_50,
  MIN_55,
  MIN_60,
}

export enum CONFIG_REST_TIME_OPTION_ARRAY {
  MIN_5,
  MIN_10,
  MIN_15,
  MIN_20,
  MIN_25,
}

export enum CONFIG_TUTORIAL_STEPS {
  TO_REST,
  TO_FOCUS,
  RESET,
  TAP,
  SHOW_HINTS,
  OFF, // skipped or finished
}

/**
 * APP configurations
 */

export const CONFIG_FOCUS_TIME_MAP_TO_MILISECONDS = {
  [CONFIG_FOCUS_TIME_OPTION_ARRAY.MIN_20]: 20 * 60 * 1000,
  [CONFIG_FOCUS_TIME_OPTION_ARRAY.MIN_25]: 25 * 60 * 1000,
  [CONFIG_FOCUS_TIME_OPTION_ARRAY.MIN_30]: 30 * 60 * 1000,
  [CONFIG_FOCUS_TIME_OPTION_ARRAY.MIN_35]: 35 * 60 * 1000,
  [CONFIG_FOCUS_TIME_OPTION_ARRAY.MIN_40]: 40 * 60 * 1000,
  [CONFIG_FOCUS_TIME_OPTION_ARRAY.MIN_45]: 45 * 60 * 1000,
  [CONFIG_FOCUS_TIME_OPTION_ARRAY.MIN_50]: 50 * 60 * 1000,
  [CONFIG_FOCUS_TIME_OPTION_ARRAY.MIN_55]: 55 * 60 * 1000,
  [CONFIG_FOCUS_TIME_OPTION_ARRAY.MIN_60]: 60 * 60 * 1000,
};
export const CONFIG_REST_TIME_MAP_TO_MILISECONDS = {
  [CONFIG_REST_TIME_OPTION_ARRAY.MIN_5]: 5 * 60 * 1000,
  [CONFIG_REST_TIME_OPTION_ARRAY.MIN_10]: 10 * 60 * 1000,
  [CONFIG_REST_TIME_OPTION_ARRAY.MIN_15]: 15 * 60 * 1000,
  [CONFIG_REST_TIME_OPTION_ARRAY.MIN_20]: 20 * 60 * 1000,
  [CONFIG_REST_TIME_OPTION_ARRAY.MIN_25]: 25 * 60 * 1000,
};

export const CONFIG_FOCUS_TIME_OPTION_DEFAULT: CONFIG_FOCUS_TIME_OPTION_ARRAY =
  CONFIG_FOCUS_TIME_OPTION_ARRAY.MIN_25;
export const CONFIG_REST_TIME_OPTION_DEFAULT: CONFIG_REST_TIME_OPTION_ARRAY =
  CONFIG_REST_TIME_OPTION_ARRAY.MIN_5;

export const CONFIG_TUTORIAL_STEP_DEFAULT: CONFIG_TUTORIAL_STEPS =
  CONFIG_TUTORIAL_STEPS.TO_REST;

export const CONFIG_IS_KEEP_WAKE_DEFAULT = true;
export const CONFIG_IS_PLAY_SOUND_OPTION_DEFAULT = true;
export const CONFIG_IS_VIBRATION_OPTION_DEFAULT = true;

export const SHOW_INSTALL_MODAL_ONCE_A = 29.5 * 24 * 60 * 60 * 1000; // one moon cycle

/**
 * UI configurations
 */

// effetcs on page transition duration and theme color change delays
// every css styles uses --animation-speed that uses this const as basis
export const UI_ANIMATION_SPEED = 0.2;

export const THEME_COLOR = "#ff6b6b";
export const MAP_THEME_COLOR = {
  [TimerType.FOCUS]: THEME_COLOR,
  [TimerType.REST]: "#51cf66",
};
// mix with 50% black
export const MAP_THEME_COLOR_MUTED = {
  [TimerType.FOCUS]: "#7f3636",
  [TimerType.REST]: "#2b6733",
};
