export default function useThemeColor() {
  const setThemeColor = (color: string) => {
    document.querySelector("#themeColorMeta")?.setAttribute("content", color);
  };
  const matchThemeColor = () => {
    const theme = document
      .querySelector("html")
      ?.getAttribute("data-mantine-color-scheme");
    setThemeColor(theme === "dark" ? "#1a1b1e" : "#ffffff"); // TODO: think of where to put colors
  };
  return { setThemeColor, matchThemeColor };
}
