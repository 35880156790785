/**
 * Check permissions
 */

const notification = () => {
  return "Notification" in window && Notification.permission === "granted";
};
notification.default = () =>
  "Notification" in window && Notification.permission === "default";
notification.granted = () =>
  "Notification" in window && Notification.permission === "granted";
notification.denied = () =>
  "Notification" in window && Notification.permission === "denied";

export default {
  notification,
};
