export const useScreenLock = () => {
  let wakeLockObj: WakeLockSentinel | null = null;

  return {
    /**
     * Tries to lock the screen
     * @returns null if no API is available, true if succeed and false if error (denied)
     */
    lock: async (): Promise<null | boolean> => {
      if (!("wakeLock" in navigator)) return null;

      try {
        const wakeLock = await navigator.wakeLock.request("screen");
        wakeLockObj = wakeLock;

        // if release externally (by user or OS)
        wakeLockObj.addEventListener("release", () => {
          wakeLockObj = null;
        });

        return true;
      } catch (err: unknown) {
        // can be declined, set option to false in this case

        console.error(err);

        return false;
      }
    },
    unlock: () => {
      wakeLockObj?.release();
      wakeLockObj = null;
    },
  };
};
